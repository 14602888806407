<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张磊
 * @Date: 2021-05-17 00:06:06
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-17 02:11:20
 * @FilePath: /官网迭代/src/views/admin/teacher/testCheck.vue
-->
<template>
  <div class="attend_class_page">
    <div class="left">
      <div class="block">
        <div class="first_title tab">上课班级</div>
        <div
          class="item_title tab"
          v-for="item in classList"
          :key="item.id"
          :class="{ active: item.id === tabClassId }"
          @click="checkClass(item)"
        >
          {{ item.type == 2 ? '小班' : item.type == 1 ? '中班' : '大班' }} ·
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <div class="radio_block tab" @click="handleRadioAll">
          <div class="radio" :class="{ active: allCheckFlag }"></div>
          <div class="title">全部</div>
        </div>
        <div class="radio_block tab" @click="handleCancelRadioAll">
          <div class="radio" :class="{ active: allClearFlag }"></div>
          <div class="title">清空</div>
        </div>
        <div class="radio_block">选择学员</div>
      </div>
      <div class="select">
        <div class="title">
          <span>选择上课老师</span>
        </div>
        <div class="check_block">
          <div
            class="check_block_item tab"
            v-for="item in atteTeacherList"
            :key="item.id"
            @click="item.isCheck = !item.isCheck"
          >
            <div class="check" :class="{ active: item.isCheck }"></div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="select">
        <div class="title">
          <span>选择学生</span>
        </div>
        <div class="check_block">
          <div
            class="check_block_item tab"
            v-for="item in atteStudentList"
            :key="item.id"
            @click="item.isCheck = !item.isCheck"
          >
            <div class="check" :class="{ active: item.isCheck }"></div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="btns">
        <div
          class="btn tab"
          style="background: #cc1a30; margin-right: 0.2rem"
          @click="toTest(0)"
        >
          学期初测试
        </div>
        <div class="btn tab" style="background: #228cec" @click="toTest(1)">
          学期末测试
        </div>
      </div>
      <div
        style="
          font-weight: bold;
          color: #cb1b31;
          text-align: right;
          margin-bottom: 0.85rem;
        "
      >
        未做学期初测试请先做学期初测试，做完后方可做学期末测试，且学期的前两个月只能做学期初测试。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'evaluateComponent',
  components: {},
  data() {
    return {
      atteStudentList: [],
      atteTeacherList: [],
      videoList: [],
      classList: [],
      tabClassId: '',
      tabVideo: {},
      semesterId: '',
      tabClass: {},
    }
  },
  created() {},
  computed: {
    allCheckFlag() {
      let res = true
      this.atteTeacherList.forEach((item) => {
        if (!item.isCheck) {
          res = false
        }
      })
      this.atteStudentList.forEach((item) => {
        if (!item.isCheck) {
          res = false
        }
      })
      return res
    },
    allClearFlag() {
      let res = true
      this.atteTeacherList.forEach((item) => {
        if (item.isCheck) {
          res = false
        }
      })
      this.atteStudentList.forEach((item) => {
        if (item.isCheck) {
          res = false
        }
      })
      return res
    },
  },
  mounted() {
    this.getClassList()
  },
  methods: {
    handleRadioAll() {
      this.atteStudentList.forEach((item) => {
        item.isCheck = true
      })
      this.atteTeacherList.forEach((item) => {
        item.isCheck = true
      })
    },
    handleCancelRadioAll() {
      this.atteStudentList.forEach((item) => {
        item.isCheck = false
      })
      this.atteTeacherList.forEach((item) => {
        item.isCheck = false
      })
    },
    //  获取上课班级
    getClassList() {
      this.api.pes
        .findTestGrade({
          schoolId: this.$store.state.userInfo.schoolId,
          gradeId: this.$route.query.gradeId,
        })
        .then((res) => {
          this.classList = [res.data.atteGrade]
          this.tabClassId = res.data.atteGrade.id
          this.atteStudentList = res.data.atteStudents.map((item) => {
            return {
              ...item,
              isCheck: true,
            }
          })
          this.atteTeacherList = res.data.atteTeachers.map((item) => {
            return {
              ...item,
              isCheck: true,
            }
          })
          this.semesterId = res.data.semesterId
        })
    },
    checkClass(item) {
      this.tabClassId = item.id
      this.tabClass = item
      this.getList(item)
    },
    // 跳转测试
    toTest(item) {
      let arr = this.atteStudentList.map((item) => {
        if (item.isCheck) {
          return item.id
        }
      })
      let a=[]
      for(let i=0;i<arr.length;i++){
           if (arr[i]!=undefined) {
             a.push(arr[i])
           }
       }  
      let arr2 = this.atteTeacherList.map((item) => {
        if (item.isCheck) {
          return item.id
        }
      })
      let b=[]
      for(let i=0;i<arr2.length;i++){
           if (arr2[i]!=undefined) {
             b.push(arr2[i])
           }
       }  
      if(!b.length){
        this.$notice({
            message: '请选择老师',
        }).isShow();
        return;
      }
      if(!a.length){
        this.$notice({
            message: '请选择学生',
        }).isShow();
        return;
      }
      this.$router.push({
        path:
          this.$route.query.isSports === 'false'
            ? '/admin/atte_foundation'
            : '/admin/atte_sports',
        query: {
          isEnd: item,
          schoolId: this.classList[0].schoolId,
          gradeId: this.$route.query.gradeId,
          semesterId: this.semesterId,
          stuIds: a.join(','),
          teacherId: b.join(','),
          gradeType: this.classList[0].type,
          sportId: this.$route.query.sportId,
          testlevel: this.$route.query.testlevel,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.attend_class_page {
  width: 100%;
  padding-top: 73px;
  @include flex(row, center, flex-start);

  .left {
    .block {
      box-sizing: border-box;
      margin-bottom: 13px !important;
      font-family: Alibaba PuHuiTi;
      font-size: 31px !important;

      .first_title {
        width: 266px;
        height: 84px;
        border-top-right-radius: 45px;
        background: #cc1a30;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        line-height: 81px;
      }

      .item_title {
        width: 266px;
        height: 69px;
        background: #f8f5f5;
        font-weight: 400;
        color: #666666;
        font-size: 24px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #cc1a30;
        margin-bottom: 7px;
        @include flex();
      }

      .active {
        color: #cc1a30;
        position: relative;
      }

      .active::after {
        content: '';
        position: absolute;
        top: 20px;
        right: 10px;
        width: 10px;
        height: 29px;
        @include bg('../../../assets/img/lesson/leftsideactive.png');
      }
    }
  }

  .right {
    width: calc(100vw - 266px);
    min-width: 1351px;
    margin: 0 186px 0 110px;

    .top {
      width: 100%;
      @include flex(row, flex-end, center);

      .radio_block {
        @include flex();
        font-size: 26px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #333333;
        margin-left: 32px;

        .radio {
          width: 31px;
          height: 30px;
          border-radius: 50%;
          background: #e1e2e2;
          margin-right: 7px;
        }
      }
    }

    .select {
      margin-bottom: 28px;

      .title {
        font-size: 26px;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #333333;
        line-height: 63px;
        text-align: center;
        border-bottom: 4px solid #bfbfbf;
        margin-bottom: 27px;

        span {
          display: block;
          width: 224px;
          height: 63px;
          border-top-right-radius: 30px;
          background: #f5f6f7;
        }
      }

      .check_block {
        @include flex(row, flex-start, center);
        flex-wrap: wrap;
        padding-left: 24px;

        .check_block_item {
          margin-right: 55px;
          margin-bottom: 10px;
          @include flex();

          .check {
            width: 16px;
            height: 16px;
            background: #e1e2e2;
            border-radius: 50%;
            margin-right: 10px;
          }

          .text {
            font-size: 25px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }

    .btns {
      @include flex(row, flex-end, center);

      .btn {
        width: 183px;
        height: 54px;
        background: #3565b5;
        font-size: 25px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #ffffff;
        @include flex();
      }
    }

    .active {
      background: #cc1a30 !important;
    }
  }
}
</style>
